import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const DGLetterCreationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })

    const onTextChange = (form, id, value) => {
        if (id === "BodyA") {
            form.updateValue('BodyACharactersLeft', 2000 - value.length);
        } else if (id === "BodyB") {
            form.updateValue('BodyBCharactersLeft', 2000 - value.length);
        }
    }

    function getParameter(value) {
        if (!utils.isNull(value)) {
            return value.Id;
        }
        else {
            return 0;
        }
    }

    function checkParameterValue(value) {
        if (!utils.isNull(value)) {
            return true;
        }
        else {
            return false;
        }
    }

    const dataArrivedHandler = (values) => {
        if (!utils.isNull(values["BodyA"])) {
            ref.current.doUpdateValue('BodyACharactersLeft', 2000 - values["BodyA"].length);
        }
        else {
            ref.current.doUpdateValue('BodyACharactersLeft', 2000);
        }
        if (!utils.isNull(values["BodyB"])) {
            ref.current.doUpdateValue('BodyBCharactersLeft', 2000 - values["BodyB"].length);
        }
        else {
            ref.current.doUpdateValue('BodyBCharactersLeft', 2000);
        }
    }

    return (
        <EntityForm ref={ref}
            viewId="dglettercreationdetails"
            dataURL='api/sims/dglettercreationdetails/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="DGLetterCreationForm">
                    <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                            <Tab label="Letter Creation" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>

                    <FormTabContainer>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dglettercreationdetails/GetLookupList?listId=FinancialYearID"
                                                id="DGFinancialYearID"
                                                key={tabValue}
                                                label="DG Financial Year"
                                                className="w-100"
                                                required={true}
                                                form={form}
                                                onChange={props.onChangeDGFinancialYearIDHandler}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/dglettercreationdetails/GetLookupListDGIntervention?parameter={"Name":"DGFinancialYearID", "Value":"' + getParameter(form.values["DGFinancialYearID"]) + '"}'}
                                                //dataUrl='api/sims/dglettercreationdetails/GetLookupListDGIntervention?parameter='
                                                id="DGInterventionID"
                                                key={tabValue}
                                                label="DG Intervention"
                                                className="w-100"
                                                required={true}
                                                form={form}
                                                disabled={!checkParameterValue(form.values["DGFinancialYearID"])}
                                                //dependency={{ Name: "DGFinancialYearID", Value: getParameter(form.values["DGFinancialYearID"])}}
                                                clearOptions={props.clearOptions}
                                                clearOptionsHandler={props.clearOptionsHandler}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dglettercreationdetails/GetLookupList?listId=CRMLetterTypeID"
                                                id="DGLetterTypeID"
                                                key={tabValue}
                                                label="DG Letter Type"
                                                className="w-100"
                                                required={true}
                                                form={form} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dglettercreationdetails/GetLookupList?listId=CRMLetterSalutationID"
                                                id="CRMLetterSalutationID"
                                                key={tabValue}
                                                label="CRM Letter Salutation"
                                                className="w-100"
                                                required={true}
                                                form={form} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="HeaderA"
                                                key={tabValue}
                                                label="Header A"
                                                className="w-100"
                                                required={true}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="HeaderB"
                                                key={tabValue}
                                                label="Header B"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="BodyA"
                                                key={tabValue}
                                                label="Body A"
                                                className="w-100"
                                                required={true}
                                                inputProps={{
                                                    maxLength: 2000
                                                }}
                                                multiline
                                                styles={styles()}
                                                form={form}
                                                onChange={onTextChange}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="BodyB"
                                                key={tabValue}
                                                label="BodyB"
                                                className="w-100"
                                                inputProps={{
                                                    maxLength: 2000
                                                }}
                                                multiline
                                                styles={styles()}
                                                form={form}
                                                onChange={onTextChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="BodyACharactersLeft"
                                                label="Body A Characters Left"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="BodyBCharactersLeft"
                                                label="Body B Characters Left"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={true}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/dglettercreationdetails/GetLookupList?listId=CRMLetterGreetingID"
                                                id="CRMLetterGreetingID"
                                                key={tabValue}
                                                label="CRM Letter Greeting"
                                                className="w-100"
                                                required={true}
                                                form={form} />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="SignOffName"
                                                key={tabValue}
                                                label="Sign Off Name"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="JobTitle"
                                                key={tabValue}
                                                label="Job Title"
                                                className="w-100"
                                                required={true}
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>

                </form>
            }
        </EntityForm>
    );
})

